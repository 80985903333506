
.codex-editor .ce-toolbar__actions {
  // right: auto;
  // inset-inline-start: -5px;
}
.codex-editor__redactor {
  padding-bottom: 100px !important;
  > .ce-block {
    padding-left: 60px;
  }
}

.ce-toolbar__content .ce-toolbar__actions {
  inset-inline-start: 0;
  inset-inline-end: auto;
}

.ce-inline-tool-hyperlink--button {
}
